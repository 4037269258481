import { AnyAction } from 'redux';
import {
	all,
	put,
	select,
	takeLatest,
} from 'redux-saga/effects';
import { ActionCreatorsFactory, ReducerFactory } from '../utils/helpersRedux';
import {
	ChatStatus,
	IApiSchema,
	IChatsState,
	IStore,
	LocalStorageKeys,
} from '../types';
import { globalHandleError } from '../utils/globalHandleError';
import {
	getContactsOnline,
	getManagerChatById,
	getManagerChats,
	getManagerChatsEvents,
} from '../servise/chat';
import { getCompanyDataById, getCurrentSubscription } from '../redux-saga/actions';
import { store } from '../index';

export const CHAT_DETAILS_LIMIT = 35;

interface IActions {
	setChatList: (payload: { status: ChatStatus, list: IApiSchema['ChatBrief'][] }) => AnyAction;
	addChatList: (payload: { status: ChatStatus, list: IApiSchema['ChatBrief'][] }) => AnyAction;
	addChatListToEnd: (payload: { status: ChatStatus, list: IApiSchema['ChatBrief'][] }) => AnyAction;
	setChatCount: (payload: { status: ChatStatus, count: number }) => AnyAction;
	setChatInfo: (payload: IApiSchema['ChatBrief'] | null) => AnyAction;
	setChatHistory: (payload: IApiSchema['IChatEventBrief'][]) => AnyAction;
	addChatHistory: (payload: IApiSchema['IChatEventBrief'][]) => AnyAction;
	addChatHistoryToEnd: (payload: IApiSchema['IChatEventBrief'][]) => AnyAction;
	removeChatHistoryByType: (payload: string) => AnyAction;
	setError: (payload: boolean) => AnyAction;
	setSearchValue: (payload: string) => AnyAction;
	setSearchActiveMessageId: (payload: number | null) => AnyAction;
	setSearchFoundMessagesIds: (payload: number[]) => AnyAction;
	setRepliedMessage: (payload: IApiSchema['IChatEventBrief'] | null) => AnyAction;
	setLastMessageData: (payload: {
		channelMessageId: number;
		messageId: number;
	} | null) => AnyAction;
	setChatsOnlineContactsIds: (payload: { contactsIds: number[]; }) => AnyAction;
	addChatsOnlineContactId: (payload: { contactId: number; }) => AnyAction;
	removeChatsOnlineContactId: (payload: { contactId: number; }) => AnyAction;
	setIsProgressMessage: (payload: boolean) => AnyAction;
	setLastMessage: (payload: { chatId: number, data: IApiSchema['IChatEventBrief']['data'] }) => AnyAction;

	runSagaChatsInit: (payload: { search: string }) => AnyAction;
	runSagaChatsFetchMore: (payload: { status: ChatStatus, search: string }) => AnyAction;
	runSagaChatInfo: (payload: { chatId?: number }) => AnyAction;
	runSagaChatEvents: (payload: { chatId?: number, loadMore?: boolean }) => AnyAction;
	runSagaSearchInChat: (payload: { searchValue: string, chatId: number }) => AnyAction;
}

const initialState: IChatsState = {
	list: {
		new: [],
		open: [],
		closed: [],
	},
	chatsCount: {
		new: 0,
		open: 0,
		closed: 0,
	},
	chatInfo: null,
	chatHistory: [],
	onlineContactsIds: [],
	search: {
		value: '',
		activeMessageId: null,
		foundMessagesIds: [],
	},
	error: false,
	repliedMessage: null,
	lastMessageData: null,
	isProgressMessage: false,
};

export const selectChatsListByStatus = (status: ChatStatus) => (
	(state: IStore) => state.chats.list[status]
);
export const selectChatsCountByStatus = (status: ChatStatus) => (
	(state: IStore) => state.chats.chatsCount[status]
);
export const selectChatsChatError = (state: IStore) => state.chats.error;
export const selectChatsChatInfo = (state: IStore) => state.chats.chatInfo;
export const selectChatsChatHistory = (state: IStore) => state.chats.chatHistory;
export const selectChatsSearchValue = (state: IStore) => state.chats.search.value;
export const selectChatsOnlineContactsIds = (state: IStore) => state.chats.onlineContactsIds;
export const selectChatsSearchActiveMessageId = (state: IStore) => (
	state.chats.search.activeMessageId
);
export const selectChatsSearchFoundMessagesIds = (state: IStore) => (
	state.chats.search.foundMessagesIds
);
export const selectChatsRepliedMessage = (state: IStore) => (
	state.chats.repliedMessage
);
export const selectChatsIsProgressMessage = (state: IStore) => (
	state.chats.isProgressMessage
);

const actionCreatorsForCallSaga = {
	runSagaChatsInit() {},
	runSagaChatInfo() {},
	runSagaChatEvents() {},
	runSagaSearchInChat() {},
	runSagaChatsFetchMore() {},
};

const removeDuplicates = (item: { id?: number }, index: number, arr: { id?: number }[]) => (
	arr.findIndex((el) => el.id === item.id) === index
);

const lastMessageHelper = (
	item: IApiSchema['ChatBrief'],
	payload: { chatId: number, data: IApiSchema['IChatEventBrief']['data'] },
) => (
	item.id === payload.chatId
		? { ...item, last_message: payload.data }
		: item
);

export const actionsChats = ActionCreatorsFactory<IActions>(
	{ moduleName: 'chats' },
	{
		...actionCreatorsForCallSaga,

		setChatList: (state: IChatsState, payload: {
			status: ChatStatus,
			list: IApiSchema['ChatBrief'][],
		}): IChatsState => ({
			...state,
			list: {
				...state.list,
				[payload.status]: payload.list,
			},
		}),

		addChatList: (state: IChatsState, payload: {
			status: ChatStatus,
			list: IApiSchema['ChatBrief'][],
		}): IChatsState => ({
			...state,
			list: {
				...state.list,
				[payload.status]: [
					...payload.list,
					...state.list[payload.status],
				].filter(removeDuplicates),
			},
		}),

		addChatListToEnd: (state: IChatsState, payload: {
			status: ChatStatus,
			list: IApiSchema['ChatBrief'][],
		}): IChatsState => ({
			...state,
			list: {
				...state.list,
				[payload.status]: [
					...state.list[payload.status],
					...payload.list,
				].filter(removeDuplicates),
			},
		}),

		setChatCount: (state: IChatsState, payload: {
			status: ChatStatus,
			count: number,
		}): IChatsState => ({
			...state,
			chatsCount: {
				...state.chatsCount,
				[payload.status]: payload.count,
			},
		}),

		setChatInfo: (state: IChatsState, payload: IApiSchema['ChatBrief'] | null): IChatsState => ({
			...state,
			chatInfo: payload,
		}),

		setChatHistory: (state: IChatsState, payload: IApiSchema['IChatEventBrief'][]): IChatsState => ({
			...state,
			chatHistory: payload,
		}),

		addChatHistory: (state: IChatsState, payload: IApiSchema['IChatEventBrief'][]): IChatsState => ({
			...state,
			chatHistory: [
				...payload,
				...state.chatHistory,
			].filter(removeDuplicates),
		}),

		addChatHistoryToEnd: (state: IChatsState, payload: IApiSchema['IChatEventBrief'][]): IChatsState => ({
			...state,
			chatHistory: [
				...state.chatHistory,
				...payload,
			].filter(removeDuplicates),
		}),

		removeChatHistoryByType: (state: IChatsState, payload: string): IChatsState => ({
			...state,
			chatHistory: state.chatHistory.filter((item) => item.type !== payload),
		}),

		setError: (state: IChatsState, payload: boolean): IChatsState => ({
			...state,
			error: payload,
		}),

		setSearchValue: (state: IChatsState, payload: string): IChatsState => ({
			...state,
			search: {
				...state.search,
				value: payload,
			},
		}),

		setSearchActiveMessageId: (state: IChatsState, payload: number | null): IChatsState => ({
			...state,
			search: {
				...state.search,
				activeMessageId: payload,
			},
		}),

		setSearchFoundMessagesIds: (state: IChatsState, payload: number[]): IChatsState => ({
			...state,
			search: {
				...state.search,
				foundMessagesIds: payload,
			},
		}),

		setRepliedMessage: (state: IChatsState, payload: IApiSchema['IChatEventBrief'] | null): IChatsState => ({
			...state,
			repliedMessage: payload,
		}),

		setLastMessageData: (
			state: IChatsState,
			payload: { channelMessageId: number; messageId: number } | null,
		): IChatsState => ({
			...state,
			lastMessageData: payload,
		}),

		setChatsOnlineContactsIds: (
			state: IChatsState,
			payload: { contactsIds: number[]; },
		): IChatsState => ({
			...state,
			onlineContactsIds: payload.contactsIds,
		}),

		addChatsOnlineContactId: (
			state: IChatsState,
			payload: { contactId: number; },
		): IChatsState => ({
			...state,
			onlineContactsIds: [...state.onlineContactsIds, payload.contactId],
		}),

		removeChatsOnlineContactId: (
			state: IChatsState,
			payload: { contactId: number; },
		): IChatsState => ({
			...state,
			onlineContactsIds: state.onlineContactsIds.filter((id) => id !== payload.contactId),
		}),

		setIsProgressMessage: (
			state: IChatsState,
			payload: boolean,
		): IChatsState => ({
			...state,
			isProgressMessage: payload,
		}),

		setLastMessage: (
			state: IChatsState,
			payload: { chatId: number, data: IApiSchema['IChatEventBrief']['data'] },
		): IChatsState => ({
			...state,
			list: {
				new: state.list.new.map((i) => lastMessageHelper(i, payload)),
				open: state.list.open.map((i) => lastMessageHelper(i, payload)),
				closed: state.list.closed.map((i) => lastMessageHelper(i, payload)),
			},
		}),
	},
);

// HELPERS

export enum EnumChatEventType {
	operator_connected = 'operator_connected',
	contact_online = 'contact_online',
	contact_offline = 'contact_offline',
	plan_prolonged = 'plan_prolonged',
	plan_updated = 'plan_updated',
	chat_closed = 'chat_closed',
	contact_not_typing = 'contact_not_typing',
	contact_typing = 'contact_typing',

	chat_started = 'chat_started',
	forwarded_operator = 'forwarded_operator',
	forwarded_department = 'forwarded_department',
	operator_joined = 'operator_joined',
	operator_rated = 'operator_rated',
	contact_message = 'contact_message',
	operator_message = 'operator_message',
	system_message = 'system_message',
	callback_message = 'callback_message',

	get_channel_message_id = 'get_channel_message_id',
}

export enum EnumChatEventStatus {
	ok = 'ok',
	error = 'error',
}

interface IWebSocketMessage {
	data?: IApiSchema['IChatEventBrief']['data'],
	type: EnumChatEventType;
	status?: EnumChatEventType & EnumChatEventStatus;
	event_type?: EnumChatEventType;
	contact_id?: number;
	chat_id?: number;
	channel_message_id?: number;
	message_id?: number;
}

export const addChatItem = (item: IApiSchema['ChatBrief'], status: ChatStatus) => {
	const { chatsCount, list, chatInfo } = store.getState().chats;

	const hasInChats = list[status].find((el: IApiSchema['ChatBrief']) => el.id === item.id);

	if (!hasInChats) {
		store.dispatch(actionsChats.addChatList({
			status,
			list: [{
				...item,
				status,
			}],
		}));

		store.dispatch(actionsChats.setChatCount({
			status,
			count: chatsCount[status] + 1,
		}));
	}

	if (item.id === chatInfo?.id) {
		store.dispatch(actionsChats.setChatInfo({
			...item,
			status,
		}));
	}
};

export const removeChatItem = (chatId: number, status: ChatStatus) => {
	const { chatsCount, list } = store.getState().chats;

	if (list[status].find((item) => item.id === chatId)) {
		store.dispatch(actionsChats.setChatList({
			status,
			list: list[status].filter((item) => item.id !== chatId),
		}));

		store.dispatch(actionsChats.setChatCount({
			status,
			count: chatsCount[status] - 1,
		}));
	}
};

const handleChatEvent = async (chatIdFromMessage: number, status: ChatStatus) => {
	const statuses: ChatStatus[] = ['new', 'open', 'closed'];
	if (chatIdFromMessage) {
		const chat = await getManagerChatById({
			chat_id: chatIdFromMessage,
		});

		addChatItem(chat, status);
	}

	statuses.forEach((statusLocal) => {
		if (statusLocal !== status) {
			removeChatItem(chatIdFromMessage, statusLocal);
		}
	});
};

export const handleMessageFromWebsocket = async (messageData?: IWebSocketMessage) => {
	const companyId = store.getState().user.details.customer_id;
	const { chatInfo } = store.getState().chats;

	const chatIdFromMessage = messageData?.chat_id || chatInfo?.id || 0;

	if (
		messageData?.status
		&& [EnumChatEventType.contact_online].includes(messageData?.status)
	) {
		store.dispatch(actionsChats.addChatsOnlineContactId({
			contactId: messageData.contact_id || 0,
		}));
	}

	if (
		messageData?.status
		&& [EnumChatEventType.contact_offline].includes(messageData?.status)
	) {
		store.dispatch(actionsChats.removeChatsOnlineContactId({
			contactId: messageData.contact_id || 0,
		}));
	}

	if (messageData?.event_type === EnumChatEventType.get_channel_message_id) {
		store.dispatch(actionsChats.setLastMessageData({
			channelMessageId: messageData.channel_message_id || 0,
			messageId: messageData.message_id || 0,
		}));
	}

	if (messageData?.type === EnumChatEventType.operator_message && messageData.status === 'ok') {
		store.dispatch(actionsChats.setIsProgressMessage(false));
	}

	if (messageData?.status === 'error') {
		store.dispatch(actionsChats.setError(true));
	}

	if (messageData?.type === EnumChatEventType.chat_closed) {
		handleChatEvent(chatIdFromMessage, 'closed');
	}

	if (messageData?.status === 'ok') {
		return;
	}

	if (
		messageData?.type
		&& [EnumChatEventType.chat_started, EnumChatEventType.forwarded_operator]
			.includes(messageData?.type)
	) {
		handleChatEvent(chatIdFromMessage, 'new');
	}

	if (messageData?.type === EnumChatEventType.operator_joined) {
		handleChatEvent(chatIdFromMessage, 'open');
	}

	if (messageData?.type === EnumChatEventType.contact_message && messageData?.data) {
		store.dispatch(actionsChats.setLastMessage({
			chatId: messageData.chat_id || 0,
			data: messageData?.data,
		}));
	}

	switch (messageData?.type) {
		case EnumChatEventType.plan_prolonged:
		case EnumChatEventType.plan_updated: {
			localStorage.setItem(LocalStorageKeys.subscriptionEndsAlertClosed, '');

			store.dispatch(getCurrentSubscription());

			if (window.location.pathname.includes('/customers')) {
				store.dispatch(getCompanyDataById(companyId || 0));
			}

			break;
		}
		case EnumChatEventType.contact_not_typing: {
			if (messageData?.contact_id === chatInfo?.contact_id) {
				store.dispatch(actionsChats.removeChatHistoryByType(EnumChatEventType.contact_typing));
			}

			break;
		}
		case EnumChatEventType.contact_typing: {
			if (messageData?.contact_id === chatInfo?.contact_id) {
				store.dispatch(actionsChats.addChatHistoryToEnd([{
					id: Date.now(),
					type: EnumChatEventType.contact_typing,
					chat_id: chatInfo?.id || 0,
					data: {},
				}]));
			}

			break;
		}
		case EnumChatEventType.operator_joined:
		case EnumChatEventType.forwarded_operator:
		case EnumChatEventType.chat_started:
		case EnumChatEventType.chat_closed:
		case EnumChatEventType.operator_rated:
		case EnumChatEventType.callback_message:
		case EnumChatEventType.system_message:
		case EnumChatEventType.contact_message: {
			if (messageData?.contact_id === chatInfo?.contact_id) {
				store.dispatch(actionsChats.addChatHistoryToEnd([
					messageData as IApiSchema['IChatEventBrief'],
				]));
			}
			break;
		}
		default:
	}
};

// SAGAS

function* doChatListByStatus(payload: { search: string, status: ChatStatus }) {
	try {
		const searchParams = new URLSearchParams(payload.search);

		const response: IApiSchema['LimitOffsetPage_ChatBrief_'] = yield getManagerChats({
			status: payload.status,
			query: searchParams.get('searchValue') as string,
			operator_id: searchParams.get('member_id') ? Number(searchParams.get('member_id')) : undefined,
			operators: searchParams.get('chat_type') ? true : undefined,
			from_time: searchParams.get('start-date') as string,
			to_time: searchParams.get('end-date') as string,
		});

		yield put(actionsChats.setChatList({ status: payload.status, list: response?.items || [] }));
		yield put(actionsChats.setChatCount({ status: payload.status, count: response?.total || 0 }));
	} catch (error) {
		globalHandleError({
			module: 'chats',
			subModule: 'doChatListByStatus',
			error,
		});
	}
}

function* doChatContactsOnline() {
	try {
		const response: number[] = yield getContactsOnline();

		yield put(actionsChats.setChatsOnlineContactsIds({ contactsIds: response }));
	} catch (error) {
		globalHandleError({
			module: 'chats',
			subModule: 'doChatContactsOnline',
			error,
		});
	}
}

function* doChatsInitSaga(action: { payload: { search: string } }) {
	try {
		yield doChatListByStatus({ search: action.payload.search, status: 'new' });
		yield doChatListByStatus({ search: action.payload.search, status: 'open' });
		yield doChatListByStatus({ search: action.payload.search, status: 'closed' });
		yield doChatContactsOnline();
	} catch (error) {
		globalHandleError({
			module: 'chats',
			subModule: 'doChatsInitSaga',
			error,
		});
	}
}

function* doChatsFetchMoreSaga(action: { payload: { status: ChatStatus, search: string } }) {
	try {
		const { payload } = action;

		const searchParams = new URLSearchParams(payload.search);

		const chats: IApiSchema['ChatBrief'][] = yield select(selectChatsListByStatus(payload.status));

		const response: IApiSchema['LimitOffsetPage_ChatBrief_'] = yield getManagerChats({
			status: payload.status,
			offset: chats.length,
			query: searchParams.get('searchValue') as string,
			operator_id: searchParams.get('member_id') ? Number(searchParams.get('member_id')) : undefined,
			operators: searchParams.get('chat_type') ? true : undefined,
			from_time: searchParams.get('start-date') as string,
			to_time: searchParams.get('end-date') as string,
		});

		yield put(actionsChats.addChatListToEnd({
			status: payload.status,
			list: response?.items || [],
		}));
	} catch (error) {
		globalHandleError({
			module: 'chats',
			subModule: 'doChatsFetchMoreSaga',
			error,
		});
	}
}

function* doSearchInChatSaga(action: { payload: { searchValue: string, chatId: number } }) {
	try {
		const { searchValue, chatId } = action.payload;

		yield put(actionsChats.setSearchValue(searchValue));

		if (!searchValue) {
			yield put(actionsChats.setSearchFoundMessagesIds([]));
		} else {
			const response: IApiSchema['LimitOffsetPage_IChatEventBrief_'] = yield getManagerChatsEvents({
				query: searchValue,
				chat_id: chatId,
			});

			const foundMessagesIds = response.items.map((item) => Number(item.id));

			yield put(actionsChats.setSearchFoundMessagesIds(foundMessagesIds));
		}
	} catch (error) {
		yield put(actionsChats.setSearchFoundMessagesIds([]));

		globalHandleError({
			module: 'chats',
			subModule: 'doSearchInChatSaga',
			error,
		});
	}
}

function* doChatInfoSaga(action: { payload: { chatId?: number } }) {
	try {
		const { chatId } = action.payload;

		if (chatId) {
			const chat: IApiSchema['ChatBrief'] = yield getManagerChatById({
				chat_id: chatId,
			});

			yield put(actionsChats.setChatInfo(chat || null));
		} else {
			yield put(actionsChats.setChatInfo(null));
		}
	} catch (error) {
		yield put(actionsChats.setChatInfo(null));

		globalHandleError({
			module: 'chats',
			subModule: 'doChatInfoSaga',
			error,
		});
	}
}

function* doChatEventsSaga(action: { payload: { chatId?: number, loadMore?: boolean } }) {
	try {
		const { chatId, loadMore } = action.payload;

		const chatHistory: IApiSchema['IChatEventBrief'][] = yield select(selectChatsChatHistory);

		if (chatId) {
			const response: IApiSchema['LimitOffsetPage_IChatEventBrief_'] = yield getManagerChatsEvents({
				chat_id: chatId,
				limit: CHAT_DETAILS_LIMIT,
				offset: loadMore ? chatHistory.length : 0,
			});

			if (loadMore) {
				yield put(actionsChats.addChatHistory(response.items.reverse()));
			} else {
				yield put(actionsChats.setChatHistory(response.items.reverse()));
			}
		} else {
			yield put(actionsChats.setChatHistory([]));
		}
	} catch (error) {
		yield put(actionsChats.setChatHistory([]));

		globalHandleError({
			module: 'chats',
			subModule: 'doChatEventsSaga',
			error,
		});
	}
}

export function* sagas() {
	yield all([
		// @ts-ignore
		yield takeLatest(actionsChats.runSagaChatsInit.type, doChatsInitSaga),
		// @ts-ignore
		yield takeLatest(actionsChats.runSagaChatsFetchMore.type, doChatsFetchMoreSaga),
		// @ts-ignore
		yield takeLatest(actionsChats.runSagaSearchInChat.type, doSearchInChatSaga),
		// @ts-ignore
		yield takeLatest(actionsChats.runSagaChatInfo.type, doChatInfoSaga),
		// @ts-ignore
		yield takeLatest(actionsChats.runSagaChatEvents.type, doChatEventsSaga),
	]);
}

export default ReducerFactory(initialState, actionsChats);