import React from 'react';
import { IApiSchema, IFileUploaded, LocalStorageKeys } from '../types';
import {
	ChatsSound,
	FILE_SIZE_LIMIT,
	FILE_TYPES,
	LIMIT_REPLAY_MESSAGE_TEXT,
	MessageNewSound,
	MessageOpenSound,
} from '../constants';
import { getFileType } from './getFileType';
import { getUploadedData, inputFilesAdd } from '../redux-saga/actions';
import { globalHandleError } from './globalHandleError';
import { store } from '../index';
import { getDataObjectFromLocalStorage } from './getDataObjectFromLocalStorage';
import { isValidJSON } from './json';

export const constructMessageData = (params: {
	chatId: number,
	message: string,
	repliedMessage: IApiSchema['IChatEventBrief'] | null,
	files: IFileUploaded[],
}) => {
	const {
		chatId,
		message,
		repliedMessage,
		files,
	} = params;

	const mainData: IApiSchema['IChatEventBrief'] = {
		chat_id: chatId,
		type: 'operator_message',
		data: {},
	};

	if (message) {
		mainData.data.text = message.trim();
	}

	if (repliedMessage?.data.text) {
		mainData.data.reply_message_text = repliedMessage.data.text
			.substring(0, LIMIT_REPLAY_MESSAGE_TEXT);
	}

	if (repliedMessage?.data.channel_message_id) {
		mainData.data.reply_message_id = repliedMessage.data.channel_message_id;
	}

	if (repliedMessage?.data.files?.length) {
		mainData.data.reply_message_file_data = repliedMessage?.data.files;
	}

	if (repliedMessage?.type === 'operator_message') {
		mainData.data.reply_message_type = 'operator_message';
	}

	if (repliedMessage?.type === 'contact_message') {
		mainData.data.reply_message_type = 'contact_message';
	}

	if (files.length) {
		mainData.data.files = files.map((file: IFileUploaded) => ({
			...file,
			type: file.type || getFileType(file.file_name) || '',
			url: file.url,
		}));
	}

	return mainData;
};

export const handlePasteMessage = (
	event: React.ClipboardEvent,
	disableFileUpload: boolean,
	chatId: number,
) => {
	try {
		const clipboardData: React.ClipboardEvent['clipboardData'] = event.clipboardData
			// @ts-ignore
			|| event.originalEvent.clipboardData;

		const types = [
			...Object.keys(FILE_TYPES.images),
			...Object.keys(FILE_TYPES.files),
		];

		const imageItems = Array.from(clipboardData.items)
			.filter((item) => types.includes(item.type));

		if (imageItems.length === 0) {
			return;
		}

		const files: File[] = [];

		imageItems.forEach((imageItem) => {
			const blob = imageItem.getAsFile();

			if (blob === null) {
				return;
			}

			event.preventDefault();

			if (blob.size > FILE_SIZE_LIMIT) {
				return;
			}

			const file: File & { preview?: string } = new File([blob], blob.name || 'screenshot.jpg');

			file.preview = URL.createObjectURL(blob);

			files.push(file);
		});

		if (files.length > 10) {
			files.length = 10;
		}

		if (files.length > 0 && !disableFileUpload) {
			store.dispatch(inputFilesAdd(files, chatId));
			store.dispatch(getUploadedData(files, chatId));
		}
	} catch (error) {
		event.preventDefault();

		globalHandleError({
			module: 'textarea',
			subModule: 'handlePaste',
			error,
		});
	}
};

interface IMessageData {
	type: string;
	status: string;
	chat_id: number;
	chat_data?: {
		from_operator_id: number;
		to_operator_id: number;
	};
	data?: {
		text: string;
		files: { type: string }[];
	};
}

export const playSound = (messageData: IMessageData, userId?: number) => {
	const soundNotificationsSettings = getDataObjectFromLocalStorage(
		LocalStorageKeys.soundNotifications,
	);
	const { type, status, chat_data } = messageData;

	if (
		(typeof soundNotificationsSettings?.chatSound !== 'boolean' || soundNotificationsSettings?.chatSound)
		&& (type === 'callback_message'
			|| type === 'chat_started'
			|| type === 'forwarded_operator'
			|| type === 'forwarded_department')
	) {
		ChatsSound.play();
	} else if (
		(typeof soundNotificationsSettings?.messageSound !== 'boolean' || soundNotificationsSettings?.messageSound)
		&& type === 'contact_message'
	) {
		if ((status === 'new') || (status === 'forwarded' && chat_data?.to_operator_id === userId)) {
			MessageNewSound.play();
		} else if (status === 'open' || (status === 'forwarded' && chat_data?.from_operator_id === userId)) {
			MessageOpenSound.play();
		}
	}
};

export const showPushNotification = (
	messageData: IMessageData,
	translate: (key: string) => string,
) => {
	if (messageData?.type !== 'chat_started' && messageData?.type !== 'contact_message') {
		return;
	}

	const pushNotificationsSettings = getDataObjectFromLocalStorage(
		LocalStorageKeys.pushNotifications,
	);

	if (
		typeof pushNotificationsSettings?.chatPush === 'boolean'
		&& !pushNotificationsSettings?.chatPush
		&& (messageData?.type === 'chat_started' || messageData.status === 'new')
	) {
		return;
	}

	if (
		typeof pushNotificationsSettings?.messagePush === 'boolean'
		&& !pushNotificationsSettings?.messagePush
		&& messageData.status === 'open'
	) {
		return;
	}

	const notificationTitleInner = messageData?.status === 'new'
		? `${translate('newMessage')} (${translate('newChat').toLowerCase()})`
		: translate('newMessage');

	const notificationTitle = messageData?.type === 'chat_started'
		? translate('newChat')
		: notificationTitleInner;

	const notificationBody = '';

	if (!('Notification' in window)) {
		// Check if the browser supports notifications
	} else if (Notification.permission === 'granted') {
		// eslint-disable-next-line no-new
		new Notification(notificationTitle, { body: notificationBody || '' });
	} else if (Notification.permission !== 'denied') {
		Notification.requestPermission().then((permission) => {
			if (permission === 'granted') {
				// eslint-disable-next-line no-new
				new Notification(notificationTitle, { body: notificationBody || '' });
			}
		});
	}
};

export const getMessageData = (lastJsonMessage: string) => {
	try {
		if (isValidJSON(lastJsonMessage)) {
			return JSON.parse(lastJsonMessage);
		}

		return lastJsonMessage;
	} catch (e) {
		return '';
	}
};